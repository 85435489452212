.LeaveRecords-container {
  margin-top: 20px;
}

.LeaveRecords-list {
  margin: 0;
  padding: 0;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

@media (max-width: 420px) {
  .LeaveRecords-list {
    border: 0;
  }
}
