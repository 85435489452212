.LeaveRecordsItem {
  list-style-type: none;
  font-size: 14px;
  border-top: 1px dashed #777;
}

.LeaveRecordsItem:last-child {
  border-bottom: 1px dashed #777;
}

.LeaveRecordsItem-record {
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 2s cubic-bezier(0, 0.7, 0, 1);
}

.LeaveRecordsItem-record:hover {
  background-color: #ddd;
}

.LeaveRecordsItem-record-created,
.LeaveRecordsItem-record-created:hover {
  background-color: #b6fab6;
}

.LeaveRecordsItem-record-edited,
.LeaveRecordsItem-record-edited:hover {
  background-color: #000;
  color: #fff;
}

.LeaveRecordsItem-record-line1 {
  display: flex;
  justify-content: space-between;
}

.LeaveRecordsItem-record-line2 {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.LeaveRecordsItem-record-amount {
  margin-left: 40px;
  white-space: nowrap;
}
