.Message-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff45f;
  padding: 20px 0;
}

.Message-cake {
  margin-right: 10px;
}
