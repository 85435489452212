.Header-container {
  background-color: #eee;
}

.Header-tabs {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  height: 48px;
  box-sizing: border-box;
}

.Header-tab {
  height: 100%;
  display: flex;
  align-items: center;
}

.Header-tab:last-child {
  margin-left: auto;
}

.Header-tab--active {
  background-color: #ddd;
}

.Header-tab-button {
  box-shadow: none;
  font-size: inherit;
  min-width: auto;
  padding: 0 30px;
  height: 100%;
  display: flex;
  color: #999;
}

.Header-tab-button:focus,
.Header-tab-button:hover {
  box-shadow: none;
}

.Header-tab--active .Header-tab-button {
  color: #000;
}
