.LeaveEditRecord-container {
  padding: 30px 0;
}

.LeaveEditRecord-footer {
  margin: 30px 20px 0 160px;
}

.LeaveEditRecord-footer-buttons {
  display: flex;
}

.LeaveEditRecord-delete-button {
  margin-left: auto;
}

.LeaveEditRecord-error {
  margin-top: 10px;
  margin-right: 20px;
  font-size: 14px;
}

@media (max-width: 420px) {
  .LeaveEditRecord-footer {
    margin: 30px 20px 0;
  }
}
