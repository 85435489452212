.Login-container {
  margin-top: 30px;
}

.Login-header {
  font-size: 60px;
  margin-bottom: 30px;
  padding-left: 130px;
  cursor: default;
}

.Login-coin-icon {
  width: 36px;
  height: 36px;
  margin: 0 10px 0 8px;
}

.Login-label {
  width: 120px;
  margin-right: 10px;
}

.Login-label::after {
  content: ":";
}

.Login-input {
  width: 290px;
}

.Login-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0 130px;
}

.Login-error {
  margin-left: 30px;
}

@media (max-width: 420px) {
  .Login-container {
    margin-left: 30px;
    margin-right: 30px;
  }

  .Login-header {
    margin-bottom: 50px;
    padding-left: 0;
  }

  .Login-label {
    width: auto;
    margin-right: 0;
  }

  .Login-label::after {
    content: "";
  }

  .Login-input {
    display: block;
    width: calc(100% - 20px);
  }

  .Login-footer {
    margin: 60px 0 0 0;
  }
}
