.LeaveNewRecord-container {
  padding: 30px 0;
}

.LeaveNewRecord-footer {
  margin: 30px 20px 0 160px;
}

.LeaveNewRecord-error {
  margin-top: 10px;
  margin-right: 20px;
  font-size: 14px;
}

@media (max-width: 420px) {
  .LeaveNewRecord-footer {
    margin: 30px 20px 0;
  }
}
