.MoneyNewRecord-container {
  padding: 30px 0;
}

.MoneyNewRecord-footer {
  margin: 30px 20px 0 160px;
}

.MoneyNewRecord-error {
  margin-top: 10px;
  margin-right: 20px;
  font-size: 14px;
}

@media (max-width: 420px) {
  .MoneyNewRecord-footer {
    margin: 30px 20px 0;
  }
}
