.App-container {
  margin: 0 auto 50px;
  max-width: 450px;
  font-family: Courier;
  font-size: 18px;
}

.App-loading {
  margin-top: 20px;
  text-align: center;
}

button {
  font-family: Courier;
  min-width: 80px;
  background: none;
  border: 0;
  box-shadow: 0 0 0 1px black;
  white-space: nowrap;
  flex-shrink: 0;
}

button:focus,
button:hover {
  outline: 0;
  box-shadow: 0 0 0 2px black;
}

button:disabled {
  opacity: 0.7;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
}

button.large-button {
  font-size: 18px;
  padding: 6px 15px;
}

button.small-button {
  font-size: 14px;
  padding: 3px 8px;
}

button.danger-button {
  box-shadow: 0 0 0 1px red;
  color: red;
}

button.danger-button:focus,
button.danger-button:hover {
  box-shadow: 0 0 0 2px red;
}

input {
  font-family: Courier;
  font-size: 18px;
  height: 27px;
  padding: 0;
  border: 0;
  border-bottom: 1px solid black;
  background-color: transparent; /* This removes the gray background in date inputs on Android in Chrome. */
}

input:focus {
  outline: 0;
  border-bottom: 2px solid black;
}

.invalid-input,
.invalid-input:focus {
  border-bottom: 2px solid red;
}

label {
  display: inline-block;
  text-align: right;
}

.field-container {
  margin-top: 10px;
  height: 30px;
}

.field-container:first-child {
  margin-top: 0;
}

.error-message {
  color: red;
}

@media (max-width: 420px) {
  button {
    height: 45px;
  }

  input {
    margin-top: 5px;
    padding: 0 10px;
    height: 45px;
    width: 100%;
    border: 0;
    box-shadow: 0 0 0 1px black;
    -webkit-appearance: none; /* Allows box-shadow to be rendered properly on iOS */
    border-radius: 0;
  }

  input:focus {
    border: 0;
    box-shadow: 0 0 0 2px black;
  }

  .invalid-input,
  .invalid-input:focus {
    border: 0;
    box-shadow: 0 0 0 2px red;
  }

  label {
    text-align: left;
    font-size: 14px;
  }

  .field-container {
    margin: 20px 0 0;
    height: auto;
  }
}
