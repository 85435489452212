.MoneyRecordForm-lender-and-borrower-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 390px;
}

.MoneyRecordForm-lender-container {
  display: inline-block;
}

.MoneyRecordForm-arrow {
  display: inline-block;
  vertical-align: top;
  font-size: 36px;
}

.MoneyRecordForm-borrower-container {
  display: inline-block;
}

.MoneyRecordForm-lender-button {
  width: 150px;
  margin-top: 1px;
}

.MoneyRecordForm-lender-button:focus {
  box-shadow: 0 0 0 1px black;
}

/* This brings button's box-shadow on top of the adjacent button. */
.MoneyRecordForm-lender-button:hover {
  position: relative;
  z-index: 1;
}

.MoneyRecordForm-lender-selected {
  background-color: #b6fab6;
}

.MoneyRecordForm-lender-selected:hover {
  box-shadow: 0 0 0 1px black;
}

.MoneyRecordForm-borrower-button {
  width: 150px;
  margin-top: 1px;
}

.MoneyRecordForm-borrower-button:focus {
  box-shadow: 0 0 0 1px black;
}

/* This brings button's box-shadow on top of the adjacent button. */
.MoneyRecordForm-borrower-button:hover {
  position: relative;
  z-index: 1;
}

.MoneyRecordForm-borrower-selected {
  background-color: #ffbaba;
}

.MoneyRecordForm-lender-borrower:hover {
  box-shadow: 0 0 0 1px black;
}

.MoneyRecordForm-text-fields-container {
  margin-top: 40px;
}

.MoneyRecordForm-label {
  width: 150px;
  margin-right: 10px;
}

.MoneyRecordForm-label::after {
  content: ":";
}

.MoneyRecordForm-input {
  width: calc(100% - 180px);
}

@media (max-width: 420px) {
  .MoneyRecordForm-text-fields-container {
    margin: 20px 20px 0;
  }

  .MoneyRecordForm-input {
    width: calc(
      100% - 22px
    ); /*     1px     +     10px     +      10px     +      1px     */
    /* left-border   left-padding   right-padding   right-border */
  }

  .MoneyRecordForm-label {
    margin-right: 0;
  }

  .MoneyRecordForm-label::after {
    content: "";
  }
}
